<template>
  <!--    <div style="display: flex; align-items:center; cursor: pointer;">-->
  <!--&lt;!&ndash;      <img style="width: 18px; height: 18px;" src="../../../static/imgs/switch.svg" />&ndash;&gt;-->
  <!--      <i class="el-icon-caret-bottom"></i>-->
  <!--    </div>-->
  <a-select @change="handleChange" style="width: 90px" :default-value="lang">
    <a-select-option :value="item.value" select v-for="item in languageArr" :key="item.value">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("setting", ["languageArr", "lang"]),
  },
  created() {
    this.Act_initLanguage();
  },
  methods: {
    ...mapActions("setting", ["Act_setLanguage", "Act_initLanguage"]),
    handleChange(lang) {
      this.Act_setLanguage(lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>
